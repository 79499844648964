.right-section-wrapper {
    flex: 1;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    .payment-form {
        max-width: 800px;
        width: 100%;
        margin: auto;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        .form-header {
            align-items: center;
            color: #1c355e;
    
            .header-icon {
                max-width: 55px;
                padding: 10px;
                background-color: #ffc72a;
                border-radius: 100%;
                margin-right: 15px;
    
                img { width: 100% }
            }

            h4 { flex: 1 }

            .conversion {
                font-weight: bold;
                position: relative;
            }
        }
    }

    &.success {

        .payment-form { justify-content: center; }
    }

    @media (max-width: 667px) {
        .payment-form {
    
            .form-header {
                h4 { font-size: 18px }

                .header-icon {
                    max-width: 30px;
                    padding: 4px;
                }
            }
        }
    }
}

.loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    
    img {
        position: absolute;
        right: 30px;
        bottom: 0;
        width: 100px;
    }
}

.converter-toggle {
    font-size: 12px;
    text-align: right;
    width: 100%;
    cursor: pointer;
}
.curr-converter {
    position: absolute;
    right: 0;
    top: 100%;
    padding: 5px 15px 15px;
    background: #1c355e;
    border-radius: 5px;
    display: none;

    .fth-input {

        label {
            font-size: 14px;
            color: #fff;
            margin-bottom: 4px;
            font-weight: bold;
        }
    
        input {
            border: none;
            box-shadow: none;
            padding: 5px 15px;
            outline: none;
            width: 250px;
            font-size: 14px;
        }
    }

    &.show { display: block;}
}