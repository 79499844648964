.payment-info {
    padding: 50px 0;
    
    .fth-input:not(:first-child) {
        margin-top: 20px;
        align-items: flex-end;
    }

    .currency-selector {
        margin-left: 10px;
        margin-bottom: 1px;
    }

    @media (max-width: 667px) {
        padding: 30px 0;
    }
}