@use './fonts';
@use './input';

body {
    margin: 0;
    font-family: "font-primary";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    min-height: 100vh;

    > * {
        box-sizing: content-box;
    }

    button:disabled {
        opacity: 0.5;
    }
}

.font-lovely {
    font-family: font-lovely;
}