.footer-ctas {
    font-size: 18px;

    .left-btn {
        background: none;
        border: none;
        box-shadow: none;
        margin-top: 20px;

        img {
            width: 15px;
            margin-right: 10px;
        }
    }

    .right-btn {
        background: #1b1742;
        border: none;
        box-shadow: none;
        color: #fff;
        border-radius: 100px;
        padding: 10px 70px;
        margin-top: 20px;
    }
}