#successPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .check-icon {
        max-width: 100px;
        border-radius: 100%;
    }

    .h1 { 
        margin-top: 30px;
    }

    .p {
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
    }

    a.site-link {
        background: #1b1742;
        border: none;
        box-shadow: none;
        color: #fff;
        border-radius: 100px;
        padding: 10px 70px;
        margin-top: 50px;
        text-decoration: none;
    }

    button {
        background: none;
        border: none;
        box-shadow: none;
        margin-top: 30px;
        padding: 10px 0;
    }
}