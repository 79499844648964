.left-section-wrapper {
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    flex: 1;
    max-width: 45%;
    background-color: #1b1742;
    align-items: center;
    color: white;

    h1 {
        font-size: 70px;
    }

    .wrapper-bg {
        object-fit: cover;
        object-position: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0.5;
    }

    .left-text-content {
        position: relative;
        padding: 30px 70px;
    }

    .f-logo {
        width: 200px;
        filter: contrast(0) brightness(2);
    }

    .copy-rights {
        font-size: 12px;
        text-align: center;
        position: absolute;
        bottom: 50px;
        width: 100%;
    }

    .verse-ch {
        font-size: 12px;
        margin-top: 10px;
    }

    @media (max-width: 991px) {
        height: auto;
        max-width: 100%;
        position: relative;

        .copy-rights {
            display: none;
        }
    }

    @media (max-width: 667px) {
        h1 {
            font-size: 40px;
        }
        .left-text-content {
            padding: 20px;

            > div {
                font-size: 12px;
            }
        }
    }
}