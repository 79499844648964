.fth-input {

    label {
        font-size: 14px;
        color: #1c355e;
        margin-bottom: 4px;
        font-weight: bold;
    }

    input,
    textarea,
    select {
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid #000;
        padding: 10px 15px;
        width: 100%;
    }
}