.methods {
    padding: 50px 0;
    
    .method-header {
        color: #1c355e;
        font-size: 20px;
        font-weight: bold;
    }

    .method-card {
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #000;
        cursor: pointer;
        transition: 0.3s;
        margin-top: 20px;
        
        .method-logo {
            height: 50px;
        }

        .bd-instruction {
            font-size: 14px;
        }

        .method-selected {
            width: 30px;
            transition: 0.3s;
            transform: scale(0);

            &.show {
                transform: scale(1);
            }
        }

        &:hover { opacity: 0.5; }
    }

    @media (max-width: 667px) {
        padding: 30px 0;

        .method-header {
            font-size: 16px;
        }
    }
}